<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/7.png`" alt="">
      <div class="all-title-ani">联系我们</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">用先进技术帮助医疗机构提供高品质、可信赖的医疗服务赋能医疗机构全方位数智化转型，实现高质量可持续发展，帮助医疗机构开源、节流、增效，从优秀到卓越。</div>
    </div>
    <div class="contactus">
      <div class="contactus-box">
        <div>

        </div>
        <div>
          <div>云数智康总部</div>
          <div></div>
          <div>电话：400-681-0626</div>
          <div>地址：北京市丰台区广安路1幢-1层</div>
          <div>邮编：100162</div>
        </div>
      </div>
      <div class="contactus-item">
        <div>北京总部</div>
        <div>电话：400-681-0626</div>
        <div>地址：北京市丰台区广安路1幢-1层</div>
        <div>邮编：100162</div>
      </div>
      <div class="contactus-item" style="height:3.8rem">
        <div>云数智康深圳分公司</div>
        <div>电话：400-681-0626</div>
        <div>地址：广东省深圳市龙华区新区大道新龙大厦5F</div>
        <div>邮编：518048</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  }

}
</script>

<style lang="scss">
.contactus {
  background: #f5f6f8;
  padding: 48px 24px 56px;
  .contactus-box {
    background: #ffffff;
    box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 24px;
    overflow: hidden;
    > div:nth-child(1) {
      width: 100%;
      height: 360px;
      background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/104.png')
        no-repeat;
      background-size: 1336px 490px;
      background-position: -484px center;
    }
    > div:nth-child(2) {
      margin-left: 24px;
      > div:nth-child(1) {
        font-size: 34px;
        font-weight: 500;
        color: #181818;
        margin: 32px 0;
      }
      > div:nth-child(2) {
        width: 96px;
        height: 8px;
        background: #0b61c4;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-bottom: 32px;
      }
      > div:nth-child(3),
      > div:nth-child(4),
      > div:nth-child(5) {
        font-size: 28px;
        font-weight: 400;
        color: #181818;
        margin-bottom: 32px;
      }
    }
  }
  .contactus-item {
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/102.png')
      no-repeat;
    background-size: 100% 100%;
    width: 48.5%;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 262px;
    margin-bottom: 24px;
    padding: 32px 0 0 24px;
    > div:nth-child(1) {
      font-size: 34px;
      font-weight: 500;
      color: #181818;
      margin-bottom: 26px;
    }
    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      font-size: 28px;
      font-weight: 400;
      color: #181818;
      margin-bottom: 24px;
    }
  }
}
</style>
